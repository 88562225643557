import { application } from "~/javascript/controllers/application"
import * as Honeybadger from "@honeybadger-io/js"

Honeybadger.configure({
  apiKey: process.env.HONEYBADGER_FRONTEND_API_KEY,
  environment: import.meta.env.MODE
})

application.handleError = (error, message, detail) => {
  console.warn(message, detail)
  Honeybadger.notify(error)
}

Honeybadger.setContext({ user: window.user })
